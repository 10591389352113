import { mapGetters } from 'vuex';

export default {
  name: 'LandingForZSU',
  mixins: [require('../../mixins/slonDataLogger'), require('../../mixins/templateEngine')],
  data() {
    return {
      logDataDefault: { event_id: 1053 },
    };
  },
  metaInfo() {
    return {
      title: 'Авто для ЗСУ - збір коштів для підтримки армії України',
      meta: [
        { name: 'description', content: 'Збір коштів та донатів на авто для ЗСУ! Пропонуємо долучитись до збору коштів на авто для допомоги ЗСУ “донатом” - AUTO.RIA' },
        { name: 'keywords', content: '' },
        { property: 'og:site_name', content: 'AUTO.RIA' },
        { property: 'og:title', content: 'Я з друзями збираю на авто для ЗСУ' },
        { property: 'og:description', content: 'І ти можеш нам допомогти! Донат — це легко! Зроби свій внесок у Перемогу' },
        { property: 'og:type', content: 'website' },
        { property: 'og:url', content: 'https://auto.ria.com/auto-for-zsu/?utm_source=copylink&utm_medium=share_zsu&utm_campaign=auto_for_zsu&r_audience=smo&r_source=copylink&r_medium=share_zsu&r_campaign=auto_for_zsu' },
        { property: 'og:image', content: 'https://cdn.riastatic.com/docs/pictures/common/1/162/16202/16202.png' },
        { property: 'og:image:width', content: '1200' },
        { property: 'og:image:height', content: '630' },
        { name: 'ROBOTS', content: 'INDEX, FOLLOW' },
      ],
      link: [
        { rel: 'alternate', hreflang: 'ru-UA', href: 'https://auto.ria.com/auto-for-zsu/' },
        { rel: 'alternate', hreflang: 'uk-UA', href: 'https://auto.ria.com/uk/auto-for-zsu/' },
        { rel: 'alternate', hreflang: 'ru', href: 'https://auto.ria.com/auto-for-zsu/' },
        { rel: 'alternate', hreflang: 'uk', href: 'https://auto.ria.com/uk/auto-for-zsu/' },
        { rel: 'alternate', hreflang: 'x-default', href: 'https://auto.ria.com/auto-for-zsu/' },
        { rel: 'canonical', href: 'https://auto.ria.com/uk/auto-for-zsu/' },
      ],
    };
  },
  init({ store, context }) {
    const headers = context?.request?.headers || {};
    return store.dispatch('donation/fetchData', { headers });
  },
  components: {
    SVGIcon: () => import('./svgIcon.vue'),
    Heading: () => import('./Heading/index.vue'),
    CarsQueue: () => import('./CarsQueue/index.vue'),
    Histories: () => import('./Histories/index.vue'),
    Shops: () => import('./Shops/index.vue'),
    HistoriesVideo: () => import('./HistoriesVideo/index.vue'),
    Partners: () => import('./Partners/index.vue'),
    FAQ: () => import('./FAQ/index.vue'),
    StickyBtn: () => import('./StickyBtn/index.vue'),
  },
  computed: {
    ...mapGetters({
      donationsData: 'donation/donationsData',
      donationsLoggerData: 'donation/donationsLoggerData',
    }),
    main() {
      return this?.donationsData?.main || 0;
    },
    lastDonations() {
      return this?.donationsData?.lastDonations || 0;
    },
    statisticDonations() {
      return this?.donationsData?.statisticDonations || 0;
    },
    carsQueue() {
      return this?.donationsData?.carsQueue || 0;
    },
    histories() {
      return this?.donationsData?.histories || 0;
    },
    shops() {
      return this?.donationsData?.shops;
    },
    historiesVideo() {
      return this?.donationsData?.historiesVideo || 0;
    },
    partners() {
      return this?.donationsData?.partners || 0;
    },
    faq() {
      return this?.donationsData?.faq || 0;
    },
  },
  watch: {
    donationsLoggerData(logData) {
      if (Object.keys(logData).length) {
        this.donationsLogger(logData);
      }
    },
  },
  methods: {
    donationsLogger(logData) {
      try {
        this._slonik({ ...this.logDataDefault, ...logData });
      } catch (e) {
        console.error('===ERROR in donationsLogger===', e.toString());
      }
    },
  },
  mounted() {
    if (this.$route.query?.utm_medium === 'videoclip_s_truck') {
      window.location.href = '/news/auto-for-army/258291/furornij-zbir-done.html';
    }
    if (document?.referrer) this.logDataDefault.previous_url = encodeURI(this.clearAutoRiaUrl(document.referrer));
    this.donationsLogger({ request_type: 1 });
  },
};
